import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { cn } from "~/lib/utils";

type severity = "success" | "info" | "warning" | "error";

interface SnackbarProps {
  message: string;
  severity: severity;
  isVisible: boolean;
  onClose: () => void;
  duration?: number;
}

export function Snackbar({
  message,
  severity,
  isVisible,
  onClose,
  duration,
}: SnackbarProps) {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(onClose, duration ?? 3000);
      return () => clearTimeout(timer);
    }
  }, [isVisible, duration, onClose]);

  const severityStyle = {
    success: "bg-green-500",
    info: "bg-blue-500",
    warning: "bg-yellow-500",
    error: "bg-red-500",
  }[severity];

  return (
    isVisible && (
      <div
        className={cn(
          "fixed z-50 text-white shadow-md bottom-16 w-full",
          severityStyle
        )}
      >
        {message}
      </div>
    )
  );
}

interface SnackbarContextType {
  showSnackbar: (
    message: string,
    severity: severity,
    duration?: number,
    customComponent?: ReactNode
  ) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

interface SnackbarProviderProps {
  children: ReactNode;
}

export function SnackbarProvider({ children }: SnackbarProviderProps) {
  const [snackbar, setSnackbar] = useState<{
    message: string;
    severity: severity;
    isVisible: boolean;
    duration?: number;
    customComponent?: ReactNode;
  }>({
    message: "",
    severity: "info",
    isVisible: false,
  });

  // Show a CustomSnackbar
  const showSnackbar = (
    message: string,
    severity: severity,
    duration: number = 3000,
    customComponent?: ReactNode
  ) => {
    setSnackbar({
      message,
      severity,
      isVisible: true,
      duration,
      customComponent,
    });
  };

  // Close CustomSnackbar
  const handleClose = () => {
    setSnackbar({ ...snackbar, isVisible: false });
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      {snackbar.isVisible &&
        (snackbar.customComponent ? (
          snackbar.customComponent
        ) : (
          <Snackbar {...snackbar} onClose={handleClose} />
        ))}
    </SnackbarContext.Provider>
  );
}

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};
